import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import PhotoPostListing from '../components/PhotoPostListing'
import { css } from '@emotion/react'
import SEO from '../components/seo'
import { MOBILE_MEDIA_QUERY } from '../utils/typography'

const PhotosPostIndex = ({ data }) => {
  return (
    <Layout>
      <SEO title="Photos" />
      <h1>Photos</h1>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          ${MOBILE_MEDIA_QUERY} {
            grid-column: 1 / -1;
          }
        `}
      >
        {data.allMdx.nodes.map(post => (
          <PhotoPostListing grid node={post} key={post.id} />
        ))}
      </div>
    </Layout>
  )
}

export default PhotosPostIndex

export const pageQuery = graphql`
  query {
    allMdx(
      filter: {
        fields: { collection: { eq: "photos" } }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        excerpt
        fields {
          slug
          tagSlugs
        }
        frontmatter {
          date(formatString: "MMMM Do, YYYY")
          dataDate: date
          tags
          photos {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
